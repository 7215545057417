import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  AccessConditionsDTO,
  AssetResultDTO,
  CustomerAppointmentDTO,
  LocationDTO,
  ProjectDTO2,
  StretchDTO,
  TaskComponentValueDTO,
  UpdateAttributeValueDTO,
  UpdateWorkTaskDTO2
} from '../../../../api/api';
import AccessConditionDialog from '../../../../blocks/access-conditions-dialog/AccessConditionDialog';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import TaskTypeTemplateComponent from '../../../../blocks/tasktype-template-component/TaskTypeTemplateComponent';
import Button from '../../../../components/button/Button';
import {
  MappedProjectNumber,
  projectNumberToOption
} from '../../../../components/project-number-dropdown/ProjectNumberDropdown';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import useFormIsDirty from '../../../../hooks/useFormIsDirty';
import { WorkTaskDTOExtended } from '../../../../models/TaskType';
import { TaskComponent } from '../../../../models/TaskTypeComponent';
import AssetService from '../../../../services/AssetService';
import NotificationService from '../../../../services/NotificationService';
import ProjectsService from '../../../../services/ProjectsService';
import TaskService from '../../../../services/TaskService';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';
import { Container, Section, SectionContent, SectionHeader } from '../../../../styling/FormStyling';
import { StyledCircularProgress } from '../../../../styling/Styling';
import { locationsAreEqual } from '../../../../utils/location/locationHandling';
import { log } from '../../../../utils/logging/log';
import StretchesSection, {
  StretchContainer
} from '../../../create-task-view/details-step/sections/stretches/StretchesSection';
import TextRow from '../components/TextRow';
import NearbyTasks from '../../../../blocks/nearby-tasks/NearbyTasks';
import { CustomerAppointment } from './components/CustomerAppointment';
import { AccessConditions } from './components/AccessConditions';
import { Details } from './components/Details';
import { Information } from './components/Information';
import { SubTasks } from './components/Subtasks';
import { ExternalResources } from './components/ExternalResources';

interface Props extends TabProps {
  task?: WorkTaskDTOExtended;
  customerAppointment?: CustomerAppointmentDTO;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setWorkTaskCallback: Dispatch<SetStateAction<WorkTaskDTOExtended | undefined>>;
  scrollIntoPreviousPosition: (id: string) => void;
  workInvoice?: number;
  workInvoiceProjectNumber?: string;
}

export type AppointmentErrors = { [key in keyof CustomerAppointmentDTO]: boolean | undefined };
type TaskErrors = { description?: boolean };

const TaskDetailsStep = (props: Props) => {
  const {
    editable,
    setEditable,
    setWorkTaskCallback,
    tabId,
    scrollIntoPreviousPosition,
    workInvoice,
    workInvoiceProjectNumber
  } = props;
  const [task, setTask] = useState(props.task);
  const [selectedDepartment, setSelectedDepartment] = useState(task?.assignedToDepartment);
  const [description, setDescription] = useState(task?.description);
  const [earliestStartDate, setEarliestStartDate] = useState(task?.earliestStartDate);
  const [deadline, setDeadline] = useState(task?.deadline);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [customerAppointment, setCustomerAppointment] = useState<CustomerAppointmentDTO | undefined>(
    props.customerAppointment
  );
  const [appointmentErrors, setAppointmentErrors] = useState<AppointmentErrors>({});
  const [workTaskErrors, setWorkTaskErrors] = useState<TaskErrors>({});
  const [dynamicFieldErrors, setDynamicFieldErrors] = useState(false);
  const [remainingTime, setRemainingTime] = useState(task?.remainingTimeMin);
  const [notesForPlanning, setNotesForPlanning] = useState(task?.notesForPlanning);
  const [taskLocation, setTaskLocation] = useState<LocationDTO>(task?.taskLocation ?? {});

  const [accessConditions, setAccessConditions] = useState<AccessConditionsDTO | undefined>({});
  const [isAccessConditionsLoading, setIsAccessConditionsLoading] = useState(false);
  const [showAccessConditionsDialog, setShowAccessConditionsDialog] = useState(false);

  const [components, setComponents] = useState<TaskComponentValueDTO[] | undefined>([]);

  const [isProjectNumberIsEditable, setIsProjectNumberEditable] = useState(false);
  const [projectNumberObject, setProjectNumberObject] = useState<MappedProjectNumber>();
  const [externalProjects, setExternalProjects] = useState<ProjectDTO2[]>([]);
  const [isLoadingProjectNumbers, setIsLoadingProjectNumbers] = useState<boolean>(false);
  const [stretches, setStretches] = useState<StretchDTO[]>(task?.stretches ?? []);
  const [projectNumberAndName, setProjectNumberAndName] = useState<string>('-');

  useEffect(() => {
    setTask(props.task);
  }, [props.task]);

  useEffect(() => {
    if (task && task.assetId && task.assetType) {
      setIsAccessConditionsLoading(true);
      AssetService.getAssets(task.assetId, task.assetType)
        .then((res) => {
          const assetResultDto: AssetResultDTO[] = res;
          setAccessConditions(assetResultDto[0].accessConditions);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente adgangsforhold fra GIS.', 5000);
        })
        .finally(() => {
          setIsAccessConditionsLoading(false);
        });
    }
  }, [task]);

  useEffect(() => {
    if (props.task?.components) {
      const newValues = props.task.components.map((component) => {
        return { ...component };
      });
      setComponents(newValues);
    }
  }, [props.task?.components]);

  useEffect(() => {
    if (projectNumberAndName === '-' && task?.projectNumber) {
      TaskService.getProjectById(task.projectNumber)
        .then((res) => {
          setProjectNumberAndName(`${res.id} - ${res.name}`);
        })
        .catch((err) => {
          setProjectNumberAndName(task?.projectNumber ?? '');
          log(err);
        })
        .finally(() => {
          tabId && scrollIntoPreviousPosition(tabId);
        });
    }
  }, [projectNumberAndName, task?.projectNumber]);

  useEffect(() => {
    if (externalProjects.length === 0 && task && (editable || isProjectNumberIsEditable)) {
      setIsLoadingProjectNumbers(true);
      ProjectsService.getProjectsByCategory(undefined)
        .then((_externalProjects: ProjectDTO2[]) => {
          setExternalProjects(_externalProjects);
          if (task.projectNumber) {
            const selectedProject = _externalProjects.find((p) => p.id === task?.projectNumber);
            selectedProject && setProjectNumberObject(projectNumberToOption(selectedProject));
          }
        })
        .catch((err) => {
          log(err);
        })
        .finally(() => {
          setIsLoadingProjectNumbers(false);
        });
    }
  }, [editable, externalProjects, isProjectNumberIsEditable, task]);

  const { getConfirmation } = useConfirmationDialog();

  const { isDirty, toggleFieldIsDirty, resetFields } = useFormIsDirty();

  const dispatch = useDispatch();

  const handleChangeDynamicField = useCallback(
    (componentId: number, fieldId: number, value: any) => {
      toggleFieldIsDirty('dynamicFields', true);

      //Both components and attributes arrays and specific attribute must be copied
      setComponents((prev) => {
        if (!prev) return;

        const comp = prev.find((c) => c.id === componentId);
        if (!comp || !comp.attributes) return;

        let newComp = { ...comp, attributes: [...comp?.attributes] };
        const att = newComp.attributes?.find((a) => a.attributeId === fieldId);
        const attIndex = newComp.attributes?.findIndex((a) => a.attributeId === fieldId);

        if (att) {
          newComp.attributes[attIndex] = { ...att, value };
        }

        return prev.map((c) => {
          return c.id === newComp.id ? newComp : c;
        });
      });
    },
    [toggleFieldIsDirty]
  );

  const handleValidateTask = (): TaskErrors => {
    let errors: TaskErrors = {};

    if (!description) errors.description = true;

    return errors;
  };

  const handleValidateAppointment = (): AppointmentErrors => {
    let errors: AppointmentErrors = {};
    if (!customerAppointment?.customerName) errors.customerName = true;

    if (!customerAppointment?.customerPhoneNumber || customerAppointment?.customerPhoneNumber.length !== 8)
      errors.customerPhoneNumber = true;

    return errors;
  };

  const getNewWorkTaskDto = (componentAttributes?: UpdateAttributeValueDTO[]) => {
    const { startDate, endDate } = customerAppointment ?? {};
    const newStartDate = customerAppointment?.approvedByPlanning ? startDate : earliestStartDate;
    const newEndDate = customerAppointment?.approvedByPlanning ? endDate : deadline;

    return {
      description: description !== task?.description ? description : undefined,
      assignedToDepartmentId:
        selectedDepartment !== task?.assignedToDepartment ? selectedDepartment?.departmentId : undefined,
      earliestStartDate: newStartDate !== task?.earliestStartDate ? newStartDate : undefined,
      deadline: newEndDate !== task?.deadline ? newEndDate : undefined,
      customerAppointment,
      remainingTimeMin: remainingTime !== task?.remainingTimeMin ? remainingTime : undefined,
      taskLocation: !locationsAreEqual(taskLocation, task?.taskLocation ?? {}) ? taskLocation : undefined,
      notesForPlanning: notesForPlanning !== task?.notesForPlanning ? notesForPlanning : undefined,
      componentAttributes,
      projectLegalEntityId:
        projectNumberObject?.value.legalEntityId !== task?.projectLegalEntityId
          ? projectNumberObject?.value.legalEntityId
          : undefined,
      projectNumber: projectNumberObject?.value.id !== task?.projectNumber ? projectNumberObject?.value.id : undefined,
      stretches
    } as UpdateWorkTaskDTO2;
  };

  const saveUpdatedTask = () => {
    if (!task?.id) return;
    if (earliestStartDate && deadline && new Date(earliestStartDate) > new Date(deadline)) {
      NotificationService.error('Startdatoen ligger efter slutdatoen. Ændre dette og prøv igen');
      return;
    }

    const workTaskErrors = handleValidateTask();
    let customerAppointmentErrors = {};

    setWorkTaskErrors(workTaskErrors);

    if (customerAppointment) {
      customerAppointmentErrors = handleValidateAppointment();
      setAppointmentErrors(customerAppointmentErrors);
    }

    let mandatoryFieldsFilled = true;

    const componentAttributes = components?.reduce((acc, component): UpdateAttributeValueDTO[] => {
      component.attributes?.forEach((a) => {
        if (a.isMandatory && !a.value) mandatoryFieldsFilled = false;

        if (a.isEditable) acc.push({ value: a.value, id: a.id });
      });
      return acc;
    }, [] as UpdateAttributeValueDTO[]);

    if (!mandatoryFieldsFilled) {
      setDynamicFieldErrors(true);
    }

    if (Object.keys(customerAppointmentErrors).length || !mandatoryFieldsFilled || Object.keys(workTaskErrors).length)
      return;

    const diff = getNewWorkTaskDto(componentAttributes);
    setIsUpdatingTask(true);

    TaskService.updateWorkTask(parseInt(task?.id), diff)
      .then((newTask) => {
        setWorkTaskCallback(newTask);
        setCustomerAppointment(newTask.customerAppointment);
        setEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        NotificationService.success('Opgaven blev opdateret.');
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Der opstod en fejl i opdateringen af arbejdskortet - ændringerne er ikke gemt');
      })
      .finally(() => {
        setIsUpdatingTask(false);
      });
  };

  const cancelChanges = async () => {
    if (!isDirty) {
      setEditable(false);
      setIsProjectNumberEditable(false);
      setWorkTaskErrors({});
      setAppointmentErrors({});
      return;
    }

    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setComponents(task?.components);
      setDescription(task?.description);
      setSelectedDepartment(task?.assignedToDepartment);
      setEarliestStartDate(task?.earliestStartDate);
      setTaskLocation(task?.taskLocation ?? {});
      setNotesForPlanning(task?.notesForPlanning);
      setDeadline(task?.deadline);
      setEditable(false);
      setIsProjectNumberEditable(false);
      setCustomerAppointment(task?.customerAppointment);
      const selectedProject = externalProjects.find((p) => p.id === task?.projectNumber);
      selectedProject && setProjectNumberObject(projectNumberToOption(selectedProject));
      setWorkTaskErrors({});
      setAppointmentErrors({});
      resetFields();
    }
  };

  const saveNewProjectNumber = () => {
    if (!task?.id) return;
    setIsUpdatingTask(true);
    TaskService.updateProjectNumber(parseInt(task.id), {
      projectLegalEntityId: projectNumberObject?.value.legalEntityId,
      projectNumber: projectNumberObject?.value.id
    })
      .then(() => {
        setTask({
          ...task,
          projectNumber: projectNumberObject?.value.id,
          projectLegalEntityId: projectNumberObject?.value.id
        });
        setProjectNumberAndName(
          `${projectNumberObject?.value.id} - ${projectNumberObject?.value.name} (${projectNumberObject?.value.legalEntityId})`
        );
        setEditable(false);
        setIsProjectNumberEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        setIsProjectNumberEditable(false);
        setEditable(false);
        NotificationService.success('Projektnummeret blev opdateret.');
      })
      .catch((err) => {
        NotificationService.error('Der skete en fejl. Projektnummeret blev ikke opdateret.');
        log(err);
      })
      .finally(() => {
        setIsUpdatingTask(false);
      });
  };

  return (
    <Container>
      <Information
        setNotesForPlanning={setNotesForPlanning}
        toggleFieldIsDirty={toggleFieldIsDirty}
        editable={editable}
        task={task}
        setTaskLocation={setTaskLocation}
      />
      <SubTasks task={task} />
      <Section>
        <SectionHeader>Arbejdsbeskrivelse{editable && '*'}</SectionHeader>
        <StyledSectionContent>
          <TextRow
            inputMode={editable}
            type="multilineText"
            label="Arbejdsbeskrivelse"
            value={task?.description ?? '-'}
            onBlur={(text: string) => setDescription(text)}
            isDirty={toggleFieldIsDirty}
            error={workTaskErrors.description}
            hideLabel
          />
        </StyledSectionContent>
      </Section>
      <AccessConditions
        isAccessConditionsLoading={isAccessConditionsLoading}
        setShowAccessConditionsDialog={setShowAccessConditionsDialog}
        accessConditions={accessConditions}
        task={task}
      />
      <Details
        cancelChanges={cancelChanges}
        customerAppointment={customerAppointment}
        externalProjects={externalProjects}
        isDirty={isDirty}
        isLoadingProjectNumbers={isLoadingProjectNumbers}
        isProjectNumberIsEditable={isProjectNumberIsEditable}
        projectNumberAndName={projectNumberAndName}
        saveNewProjectNumber={saveNewProjectNumber}
        setIsProjectNumberEditable={setIsProjectNumberEditable}
        projectNumberObject={projectNumberObject}
        selectedDepartment={selectedDepartment}
        workInvoice={workInvoice}
        workInvoiceProjectNumber={workInvoiceProjectNumber}
        editable={editable}
        task={task}
        setSelectedDepartment={setSelectedDepartment}
        toggleFieldIsDirty={toggleFieldIsDirty}
        setEarliestStartDate={setEarliestStartDate}
        remainingTime={remainingTime}
        setDeadline={setDeadline}
        setRemainingTime={setRemainingTime}
        setProjectNumberObject={setProjectNumberObject}
      />

      {customerAppointment && (
        <CustomerAppointment
          appointmentErrors={appointmentErrors}
          customerAppointment={customerAppointment}
          editable={editable}
          setCustomerAppointment={setCustomerAppointment}
          toggleFieldIsDirty={toggleFieldIsDirty}
        />
      )}
      {task?.stretches && task.stretches?.length > 0 && (
        <Section>
          <SectionHeader>Strækninger</SectionHeader>
          <StretchContainer>
            <StretchesSection
              isEditable={editable}
              mode={editable ? 'write' : 'read'}
              value={stretches}
              onStretchChange={(value) => setStretches(value)}
            />
          </StretchContainer>
        </Section>
      )}
      {components &&
        components.map((c) => (
          <TaskTypeTemplateComponent
            component={c as TaskComponent}
            onChange={handleChangeDynamicField}
            mode={editable ? 'write' : 'read'}
            key={c.id}
            showError={dynamicFieldErrors}
          />
        ))}
      {editable && (
        <StyledFooter>
          <Button onClick={() => cancelChanges()} variant="secondary" data-testid="cancel-update-task-button">
            {<div>Annuller</div>}
          </Button>
          <Button onClick={() => saveUpdatedTask()} data-testid="confirm-update-task-button" disabled={!isDirty}>
            {isUpdatingTask ? <StyledCircularProgress size={16} /> : <div>Gem</div>}
          </Button>
        </StyledFooter>
      )}
      {showAccessConditionsDialog && (
        <AccessConditionDialog
          id={task?.id ?? ''}
          onClose={() => setShowAccessConditionsDialog(false)}
          assetId={task?.assetId ?? ''}
          assetType={task?.assetType}
          assetLocation={task?.taskLocation}
          accessConditions={accessConditions}
          setAccessConditions={setAccessConditions}
        />
      )}

      <ExternalResources externalLinks={task?.taskType?.externalResources} />
      <Section>
        <NearbyTasks
          assetId={task?.assetId ?? ''}
          coordinates={{
            longitude: task?.taskLocation?.longitude ?? 0,
            latitude: task?.taskLocation?.latitude ?? 0
          }}
          workTaskId={task?.id}
        />
      </Section>
    </Container>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du annullere dine ændringer til arbejdskortet?',
  bodyText: '',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

export const StyledSectionContent = styled((props) => <SectionContent {...props} direction="row" />)`
  div {
    row-gap: 24px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 12px 4px 12px;

  position: sticky;
  bottom: 0;

  z-index: ${(props) => props.theme.zIndex.main};
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  background-color: white;
  && > * {
    margin-left: 12px;
  }
`;

export default TaskDetailsStep;
